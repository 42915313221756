import React, { useMemo } from "react"
import styled from "styled-components"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

const Wrapper = styled.div`
  max-width: 100%;

  overflow: auto;

  th,
  td {
    &[data-centered="true"] {
      text-align: center;
    }
  }

  th {
    font-size: 0.8rem;
    letter-spacing: -0.2px;
    line-height: 1em;
  }

  td {
    transition: background 0.2s ease-in-out;

    &[data-centered="true"] {
      border-left: 1px solid ${props => props.theme.color.darkBg};
    }

    .check {
      font-weight: bold;
    }
  }

  tr:hover td {
    background-color: ${props => props.theme.color.mediumBg};
  }

  .responsiveTable {
    td {
      &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.color.darkBg} !important;
      }
    }

    .tdBefore {
      font-size: 0.8rem;
      line-height: 1em;
    }
  }
`

export default function InfoTable(props) {
  const columns = useMemo(
    () => [
      { header: "Title", accessor: "title" },
      { header: "Country", accessor: "country" },
      { header: "Patent No.", accessor: "patentNumber" },
      { header: "Product", accessor: "product" },
    ],
    []
  )

  const data = useMemo(
    () => [
      [
        "METHOD AND APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "Australia",
        "2009313638",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "METHOD AND APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "Canada",
        "2736153",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "METHOD AND APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "France",
        "2342138",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "METHOD AND APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "Germany",
        "602009025145.7",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "METHOD AND APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "Ireland (Republic of)",
        "2342138",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "METHOD AND APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "Italy",
        "502014000000791",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "METHOD AND APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "Spain",
        "2496691",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "METHOD AND APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "Turkey",
        "TR201410734T4",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "United Kingdom",
        "2342138",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "METHOD AND APPARATUS FOR ROTATIONALLY RESTRAINING A MIXING CONTAINER",
        "United States of America",
        "8,336,731",
        "F'REAL - ALL BLENDERS",
      ],
      [
        "BLENDER WITH ELEVATOR ASSEMBLY AND REMOVABLE SPINDLE",
        "Australia",
        "2013280314",
        "F'REAL 120 (PONY)",
      ],
      [
        "BLENDER WITH ELEVATOR ASSEMBLY AND REMOVABLE SPINDLE",
        "Belgium",
        "2863783",
        "F'REAL 120 (PONY)",
      ],
      [
        "BLENDER WITH ELEVATOR ASSEMBLY AND REMOVABLE SPINDLE",
        "Canada",
        "2879829",
        "F'REAL 120 (PONY)",
      ],
      [
        "BLENDER WITH ELEVATOR ASSEMBLY AND REMOVABLE SPINDLE",
        "France",
        "2863783",
        "F'REAL 120 (PONY)",
      ],
      [
        "BLENDER WITH ELEVATOR ASSEMBLY AND REMOVABLE SPINDLE",
        "Germany",
        "2863783",
        "F'REAL 120 (PONY)",
      ],
      [
        "BLENDER WITH ELEVATOR ASSEMBLY AND REMOVABLE SPINDLE",
        "Netherlands",
        "2863783",
        "F'REAL 120 (PONY)",
      ],
      [
        "BLENDER WITH ELEVATOR ASSEMBLY AND REMOVABLE SPINDLE",
        "United Kingdom",
        "2863783",
        "F'REAL 120 (PONY)",
      ],
      [
        "COMPACT BLENDER FOR FROZEN BEVERAGES",
        "United States of America",
        "9,386,882",
        "F'REAL 120 (PONY)",
      ],
      [
        "METHOD FOR BLENDING FOOD OR BEVERAGES",
        "United States of America",
        "9,420,917",
        "F'REAL 120 (PONY)",
      ],
      [
        "BLENDER WITH ELEVATOR ASSEMBLY AND REMOVABLE SPINDLE",
        "United States of America",
        "9,579,615",
        "F'REAL 120 (PONY)",
      ],
      [
        "RINSEABLE SPLASH SHIELD AND METHOD OF USE",
        "United States of America",
        "7,144,150",
        "F'REAL B2 & F'REAL B4 & F'REAL B6",
      ],
      [
        "RINSEABLE SPLASH SHIELD AND METHOD OF USE",
        "United States of America",
        "7,520,658",
        "F'REAL B2 & F'REAL B4 & F'REAL B6",
      ],
      [
        "RINSEABLE SPLASH SHIELD AND METHOD OF USE",
        "United States of America",
        "7,520,662",
        "F'REAL B2 & F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS",
        "Australia",
        "2010307039",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "Australia",
        "2010307042",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "SANITATION OF COMMERCIAL FROZEN FOOD PREPARATION APPARATUS",
        "Belgium",
        "2445622",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "Belgium",
        "2446425",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS",
        "Canada",
        "2,770,358",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS SANITATION",
        "Canada",
        "2,770,364",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "Canada",
        "2770371",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS SANITATION",
        "Canada",
        "2842468",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "SANITATION OF COMMERCIAL FROZEN FOOD PREPARATION APPARATUS",
        "France",
        "2445622",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "France",
        "2446425",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "SANITATION OF COMMERCIAL FROZEN FOOD PREPARATION APPARATUS",
        "Germany",
        "2445622",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "Germany",
        "2446425",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "SANITATION OF COMMERCIAL FROZEN FOOD PREPARATION APPARATUS",
        "Italy",
        "2445622",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "Italy",
        "2446425",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "SANITATION OF COMMERCIAL FROZEN FOOD PREPARATION APPARATUS",
        "Netherlands",
        "2445622",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "Netherlands",
        "2446425",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS SANITATION",
        "Spain",
        "2445622",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "Spain",
        "ES 2692527 T3",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "SANITATION OF COMMERCIAL FROZEN FOOD PREPARATION APPARATUS",
        "United Kingdom",
        "2445622",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "United Kingdom",
        "2446425",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS SANITATION",
        "United States of America",
        "8,763,515",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS",
        "United States of America",
        "8,905,626",
        "F'REAL B4 & F'REAL B6",
      ],
      [
        "COMMERCIAL FROZEN FOOD PREPARATION APPARATUS ELECTRONICS",
        "United States of America",
        "9,833,109",
        "F'REAL B4 & F'REAL B6",
      ],
      ["COMMERCIAL BLENDER DESIGN", "Australia", "201711891", "F'REAL B6"],
      [
        "COMMERCIAL BLENDER DESIGN",
        "Brazil",
        "BR30-2017-001549.2",
        "F'REAL B6",
      ],
      ["COMMERCIAL BLENDER DESIGN", "China", "ZL 2017301213832", "F'REAL B6"],
      [
        "MODULARIZATION BLENDER WITH IMPROVED WATER HEATING AND BEAM DETECTION",
        "China",
        "201780070581.8",
        "F'REAL B6",
      ],
      [
        "COMMERCIAL BLENDER DESIGN",
        "European Union",
        "DM/095 599",
        "F'REAL B6",
      ],
      [
        "BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "Indonesia",
        "IDP000079214",
        "F'REAL B6",
      ],
      [
        "BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "Indonesia",
        "IDP000079214",
        "F'REAL B6",
      ],
      [
        "COMMERCIAL BLENDER DESIGN",
        "International Design",
        "DM/095 599",
        "F'REAL B6",
      ],
      [
        "COMMERCIAL BLENDER DESIGN",
        "Korea, Republic of (KR)",
        "DM/095 599",
        "F'REAL B6",
      ],
      ["COMMERCIAL BLENDER DESIGN", "Mexico", "54259", "F'REAL B6"],
      [
        "MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "New Zealand",
        "752980",
        "F'REAL B6",
      ],
      [
        "MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "Singapore",
        "11201903700P",
        "F'REAL B6",
      ],
      ["COMMERCIAL BLENDER DESIGN", "Singapore", "DM/095 599", "F'REAL B6"],
      ["COMMERCIAL BLENDER DESIGN", "Switzerland", "DM/095 599", "F'REAL B6"],
      ["COMMERCIAL BLENDER DESIGN", "Taiwan", "D188957", "F'REAL B6"],
      ["COMMERCIAL BLENDER DESIGN", "United Kingdom", "6010476", "F'REAL B6"],
      ["BLENDER", "United Kingdom", "80955990001000", "F'REAL B6"],
      [
        "MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "United States of America",
        "10,898,031",
        "F'REAL B6",
      ],
      ["BLENDER", "United States of America", "D807691", "F'REAL B6"],
      ["COMMERCIAL BLENDER DESIGN", "Vietnam", "26874", "F'REAL B6"],
      [
        "SIMPLIFIED MOTOR CONTROL CIRCUIT WITH POWER FACTOR CORRECTION",
        "United States of America",
        "11,201,540",
        "F'REAL B6 & F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Australia",
        "2016215233",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Belgium",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "China",
        "ZL 2016800087695",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Colombia",
        "36358",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Denmark",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Finland",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "France",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Germany",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Greece",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Hong Kong",
        "HK1244192",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Indonesia",
        "IDP000068249",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Israel",
        "253654",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Italy",
        "3253267",
        "F'REAL B7",
      ],
      [
        "MIXER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Japan",
        "6728201",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Netherlands",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Norway",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Portugal",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "South Africa",
        "2017/05974",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Spain",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Sweden",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Turkey",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "United Kingdom",
        "3253267",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "United States of America",
        "16/393,824",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "United States of America",
        "10299628",
        "F'REAL B7",
      ],
    ],
    []
  )

  return (
    <Wrapper>
      <Table>
        <Thead>
          <Tr>
            {columns.map((column, colIndex) => (
              <Th key={colIndex} data-centered={colIndex > 1}>
                {column.header}
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {data.map((row, rowIndex) => (
            <Tr key={`${rowIndex}`}>
              {columns.map((column, colIndex) => (
                <Td
                  key={`${rowIndex}-${colIndex}`}
                  data-centered={colIndex > 1}
                >
                  {row[colIndex] === "X" ? (
                    <span className="check">&times;</span>
                  ) : (
                    row[colIndex]
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Wrapper>
  )
}
